import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';

import { environment } from '../../environments/environment';
import { RequestHeaderService } from '../shared/request-header/request-header.service';
import { MqttService } from './mqtt.service';
import { Conversation } from './models/conversation';
import { Message } from './models/message';
import { UserId } from '../user/model/user-id';
import { UploaderService } from '../course/files/uploader.service';
import {HttpClient} from '@angular/common/http';
import {filter, map, tap} from 'rxjs/operators';
import {Newsletter, NewsletterResponse} from '../newsletter/models/newsletter';

@Injectable()
export class ConversationService {

  private readonly conversationUrl: string = environment.baseUrl + 'messageService/conversation/';
  private readonly messageUrl: string = environment.baseUrl + 'messageService/message';
  private readonly acknowledgeMessageUrl: string = environment.baseUrl + 'messageService/readMessage/';
  private readonly acknowledgeChatUrl: string = environment.baseUrl + 'messageService/readChat/';
  private readonly uploadUrl: string = environment.baseUrl + 'messageService/fileMessage';

  private readonly newsletterUrl: string = environment.baseUrl + 'messageService/newsletter';

  private get requestArgs(): {} {
    return {
      headers: this.headerFactory.headerFactory()
    };
  }

  constructor(private http: HttpClient, private headerFactory: RequestHeaderService, private pushMessageService: MqttService, private uploaderFactory: UploaderService) {
  }

  public receiveMessagesForConversation(conversationId: number) {
    return this.pushMessageService.onMessage.pipe(
      filter(message => message.fromUserId === conversationId),
      tap(message => {
        this.http.put(`${this.acknowledgeMessageUrl}${message.id}`, {}, this.requestArgs)
          .subscribe(success => { }, console.error);
      }));
  }

  public getConversation(id: number, existingMessages: number): Observable<Conversation> {
    const url = `${this.conversationUrl}${id}?offset=${existingMessages}&count=10`;
    return this.http.get<Conversation>(url, this.requestArgs).pipe(
      tap(conversation => {
        this.http.put(`${this.acknowledgeChatUrl}${id}`, {}, this.requestArgs)
          .subscribe(success => { }, console.error);
      }));
  }

  public sendMessage(message: string, toUserId: number): Observable<Message> {
    const payload = {
      toUserId: toUserId,
      message: message,
    };
    return this.http.post(this.messageUrl, payload, this.requestArgs).pipe(
      map<Object, Message>(response => {
        return {
          id: null,
          fromUserId: null,
          toUserId: null,
          message: message,
          fromOther: false,
          sendDate: (new Date()).toUTCString(),
        };
      }));
  }

  public uploaderForReceiver(receiverUserId: UserId): FileUploader {
    const uploader = this.uploaderFactory.uploaderForUrl(this.uploadUrl);
    uploader.onBuildItemForm = (file, form) => {
      form.set('toUserId', receiverUserId);
    };
    return uploader;
  }

  public getNewsletter(): Observable<NewsletterResponse> {
    return this.http.get<NewsletterResponse>(this.newsletterUrl, this.requestArgs)
  }

  public createNewsletter(title: string, message: string, schoolId: number, active: boolean): Observable<void> {
    const payload = {
      title: title,
      message: message,
      schoolId: schoolId,
      active: active,
    };

    return this.http.post<void>(this.newsletterUrl, payload, this.requestArgs)
  }

  public updateNewsletter(id: number, title: string, message: string, active: boolean): Observable<void> {
    const payload = {
      title: title,
      message: message,
      active: active,
    };

    return this.http.put<void>(this.newsletterUrl + `/${id}`, payload, this.requestArgs)
  }
}
